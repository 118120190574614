<template>
  <div class="Client">
    <!-- BEGIN: Content-->
    <div class="app-content content">
      <div class="content-overlay"></div>
      <div class="header-navbar-shadow"></div>
    
      <div class="content-wrapper container-xxl p-0 font" >
        <banner
          title="Challans"
          :breadcrumb="[{ label: 'professional' }, { label: 'Challans' }]"
        >
        </banner>
        <div v-if="loading == true">
          <Spinner />
        </div>

        <div class="content-body" style="margin-top: -40px">
          <!-- users list start -->
          <section>
            <div class="row" style="margin-top: -20px">
              <div class="col-md-12 col-sm-12 col-xs-12">
                <div class="card custom-card">

<div class="card-body">
  <div class="row">
                  <div class="col text font">

                    <h4><b style="color: #00364f; ">Challans</b></h4>
                  </div>
                  <div class="col text-end cus-p-1">
                   
                <button type="button" data-bs-toggle="modal" data-bs-target="#new-task-modal" title="Filter"
                      class="btn text-white btn-sm" style="background-color: #00364f"> <strong> <font-awesome-icon
                          icon="filter" /></strong></button>
                          &nbsp;

                  </div>
                    <div class="col-xl-1 col-md-1 d-md-block d-xl-block d-none cus-p-1" >

                    <select
                      style="height: 30px; padding: 5px 5px 5px 5px"
                      class="form-select"
                      @change="changePageNo"
                      aria-label="Default select example"
                    >
         
                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                  </div>
                </div>



                <div class="row ">
                  <div class="d-md-none d-xl-none d-block col-3 text font cus-p-1">

                    <select
                      style="height: 30px; padding: 5px 5px 5px 5px"
                      class="form-select"
                      @change="changePageNo"
                      aria-label="Default select example"
                    >
                    <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>

                    </select>
                  </div>
               
                </div>
                <!-- start list area  -->
                  <div class="row">
                    <div
                      class="col-md-12 col-xl-12 col-sm-12"
                    
                    >
                      <div class="container-fluid table-scroll">
                        <table class="table table-hover table-sm">
                          <tr
                            class="text tr-head table-wrapper-scroll-y rounded-circle"
                            style="background-color: white"
                          >
                            <th
                              class="text-truncate"
                              style="
                                background-color: #cdcdcd;
                                border-radius: 8px 0px 0px 8px;
                              "
                            >
                              Project ID
                            </th>
                            <th class="text-truncate" style="background-color: #cdcdcd">
                              Professional
                            </th>
                            <th class="text-truncate" style="background-color: #cdcdcd">
                              Date
                            </th>
                            <th class="text-truncate" style="background-color: #cdcdcd">
                              Challan No.
                            </th>

                            <th class="text-truncate" style="background-color: #cdcdcd">
                              Challan
                            </th>
                            <th class="text-truncate" style="background-color: #cdcdcd">
                              Amount
                            </th>
                            <th class="text-truncate" style="background-color: #cdcdcd">
                              Challan Athority
                            </th>
                            <th class="text-truncate" style="background-color: #cdcdcd">
                              Note
                            </th>
                            <th class="text-truncate" style="background-color: #cdcdcd">
                              Remark
                            </th>
                            

                         
                            <th class="text-truncate" style="background-color: #cdcdcd">
                              Status
                            </th>
                            <th
                              style="
                                background-color: #cdcdcd;
                                border-radius: 0px 8px 8px 0px;
                              "
                              class="text-truncate"
                              v-if="
                                permissionCheck.view != false ||
                                permissionCheck.kycApprove != false
                              "
                            >
                              Action
                            </th>
                          </tr>
                          <tbody class="text">
                            <tr v-for="(challan, index) in challans" :key="index">
                              <!-- <td class="text-truncate" v-if="challan.client_service">
                                {{ challan.client_service.clientServiceNo }}
                              </td> -->

                              <td v-if="challan.client_service">
                              <router-link :to="`/professional/project-view/${challan.client_service.id}`">
                                <strong v-if="challan.client_service" style="color: #f21000;">
                                {{ challan.client_service.clientServiceNo }}</strong
                              >
                              </router-link>
                            
                            </td>
                              <td class="text-truncate" v-if="challan">
                               
                                <span v-if="challan.professional">
                                  
                                  <div>  <strong>   {{ challan.professional.name }}</strong></div>
                                  <div>   {{ challan.professional.code }}</div>
                                </span>
                              </td>
                              <td class="text-truncate" v-if="challan">
                                {{ dateTime(challan.date) }}
                              </td>
                              <td class="text-truncate" v-if="challan">
                                {{ challan.challanNo }}
                              </td>
                              <!-- <td class="text-truncate" v-if="clientinfo.service">
                                {{ clientinfo.service.name }}
                              </td> -->

                              <td class="text-truncate" v-if="challan">
                                <span> {{ challan.title }} </span>
                              </td>

                              <!-- <td class="text-truncate">---</td> -->
                              <!-- <td class="text-truncate">---</td>
                                <td class="text-truncate">---</td> -->
                              <!-- <td class="text-truncate text-center">
                                <span style="color: f21000">
                                  <i class="fa fa-cloud-upload" aria-hidden="true"></i>
                                </span>
                              </td> -->
                              <td class="text-truncate" v-if="challan">
                                <span v-if="challan.amt"> {{ challan.amt }} </span>
                              </td>
                              <td class="text-truncate" v-if="challan">
                                <span v-if="challan.challanAthority"> {{ challan.challanAthority }} </span>
                              </td>
                              <td v-if="challan">
                                <span v-if="challan.note"> {{ challan.note }} </span>
                              </td>
                              <td v-if="challan">
                                <span v-if="challan.remark"> {{ challan.remark }} </span>
                              </td>
                              
                              <td class="text-truncate" v-if="challan">
                                <p
                                  v-if="challan.status == 'pending'"
                                  class="btn btn-sm"
                                  style="
                                    width: 80px;
                                    background-color: gray;
                                    color: white;
                                    box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 50%);
                                    border-radius: 50px;
                                    font-size: 10px;
                                    padding: 3px 3px 3px 3px;
                                    margin-top: 10px;
                                  "
                                >
                                  <b>pending</b>
                                </p>
                                <p
                                  v-if="challan.status == 'approve'"
                                  class="btn btn-sm"
                                  style="
                                    width: 80px;
                                    background-color: green;
                                    color: white;
                                    box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 50%);
                                    border-radius: 50px;
                                    font-size: 10px;
                                    padding: 3px 3px 3px 3px;
                                    margin-top: 10px;
                                  "
                                >
                                  <b>{{ challan.status }}</b>
                                </p>

                                <p
                                  v-if="challan.status == 'reject'"
                                  class="btn btn-sm"
                                  style="
                                    width: 80px;
                                    background-color: black;
                                    color: white;
                                    box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 50%);
                                    border-radius: 50px;
                                    font-size: 10px;
                                    padding: 3px 3px 3px 3px;
                                    margin-top: 10px;
                                  "
                                >
                                  <b>{{ challan.status }}</b>
                                </p>

                              </td>
                              <td
                                class="text-truncate text-end"
                                v-if="
                                  permissionCheck.view != false ||
                                  permissionCheck.kycApprove != false
                                "
                              >
                                <div
                                  class="btn-group btn-group-sm"
                                  role="group"
                                  aria-label="..."
                                >
                                 

                                  <button
                                    v-if="permissionCheck.kycApprove != false"
                                    @click="challanAssign(challan)"
                                    data-bs-toggle="modal"
                                    data-bs-target="#chalanApprove"
                                    class="btn btns btn-sm text-white"
                                    style="
                                      background-color: green;
                                      padding: 5px 4.5px 5px 4.5px;
                                    "
                                  >
                                    <i
                                      class="fa fa-check-square-o"
                                      aria-hidden="true"
                                    ></i>
                                  </button>


                                
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
</div>



                
                      
              <div class="card-footer">
                <div class="row">

                  <div class="col-md-4 col-12 text-md-start text-center cus-p-1">

                    <p class="mb-0">
                      Showing {{ pagination.from }} to {{ pagination.to }} of {{ pagination.total }} entries
                    </p>
                  </div>
                  <div class="col-md-8 col-12 text-md-end text-center cus-p-1">
                    <ul class="pagination  justify-content-center">
                      <li class="page-item" @click="loadchallans(null, 'first')">
                        <a class="page-link" href="#" aria-label="Previous">
                          <span aria-hidden="true">&laquo;</span>
                        </a>
                      </li>
                      <li class="page-item" @click="loadchallans(null, 'prev')">
                        <a class="page-link" href="#">Previous</a>
                      </li>
                      <li class="page-item" v-if="pagination.current_page > 3"
                        @click="loadchallans(pagination.current_page - 3)">
                        <a class="page-link" href="#">{{
                          pagination.current_page - 3
                        }}</a>
                      </li>
                      <li class="page-item" v-if="pagination.current_page > 2"
                        @click="loadchallans(pagination.current_page - 2)">
                        <a class="page-link" href="#">{{
                          pagination.current_page - 2
                        }}</a>
                      </li>
                      <li class="page-item" v-if="pagination.current_page > 1"
                        @click="loadchallans(pagination.current_page - 1)">
                        <a class="page-link" href="#">{{
                          pagination.current_page - 1
                        }}</a>
                      </li>
                      <li class="active page-item" @click="loadchallans(pagination.current_page)">
                        <a class="page-link" href="#">{{ pagination.current_page }}</a>
                      </li>
                      <li class="page-item" v-if="pagination.current_page < pagination.last_page - 4"
                        @click="loadchallans(pagination.current_page + 1)">
                        <a class="page-link" href="#">{{
                          pagination.current_page + 1
                        }}</a>
                      </li>
                      <li class="page-item" v-if="pagination.current_page < pagination.last_page - 5"
                        @click="loadchallans(pagination.current_page + 2)">
                        <a class="page-link" href="#">{{
                          pagination.current_page + 2
                        }}</a>
                      </li>
                      <li class="page-item" v-if="pagination.current_page < pagination.last_page - 6"
                        @click="loadchallans(pagination.current_page + 3)">
                        <a class="page-link" href="#">{{
                          pagination.current_page + 3
                        }}</a>
                      </li>
                      <!-- <li v-if="pagination.current_page < (pagination.last_page - 4)" @click="loadchallans( pagination.current_page + 4 )"><a href="#">{{pagination.current_page + 4}}</a></li> -->
                      <li class="page-item">
                        <a class="page-link" href="#">...</a>
                      </li>

                      <!-- <li v-if="pagination.current_page < (pagination.last_page - 4)" @click="loadchallans( pagination.last_page - 4 )"><a href="#">{{pagination.last_page - 4}}</a></li> -->
                      <li class="page-item" v-if="pagination.current_page < pagination.last_page - 3"
                        @click="loadchallans(pagination.last_page - 3)">
                        <a class="page-link" href="#">{{ pagination.last_page - 3 }}</a>
                      </li>
                      <li class="page-item" v-if="pagination.current_page < pagination.last_page - 2"
                        @click="loadchallans(pagination.last_page - 2)">
                        <a class="page-link" href="#">{{ pagination.last_page - 2 }}</a>
                      </li>
                      <li class="page-item" v-if="pagination.current_page < pagination.last_page - 1"
                        @click="loadchallans(pagination.last_page - 1)">
                        <a class="page-link" href="#">{{ pagination.last_page - 1 }}</a>
                      </li>

                      <li class="page-item" @click="loadchallans(null, 'next')">
                        <a class="page-link" href="#">Next</a>
                      </li>
                      <li class="page-item" @click="loadchallans(null, 'last')">
                        <a class="page-link" href="#">&raquo;</a>
                      </li>
                    </ul>
                  </div>

                  <!-- <div class="col-md-1 col-4 text-end">
      <select
                      style="height: 30px; padding: 5px 5px 5px 5px"
                      class="form-select"
                      @change="changePageNo"
                      aria-label="Default select example"
                    >
         
                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
    </div> -->
                </div>
              </div>
                </div>
              </div>
            </div>
          </section>
          <!-- users list ends -->
        </div>
      </div>
    </div>
    <!-- END: Content-->

    <div class="sidenav-overlay"></div>
    <div class="drag-target"></div>
  </div>

  <!-- Modal -->
  <div
    class="modal fade"
    id="chalanApprove"
    tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false"
  >
    <div class="modal-dialog modal-sm">
      <div class="modal-content">
        <div class="modal-header" style="background-color: #00364f">
          <h4 class="modal-title text-white" id="exampleModalLabel">
            <strong>Challan Approve</strong>
          </h4>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
       
          <form @submit.prevent="statusChangeSave">
            <div class="row">
              <div class="col-6" style="padding:5px 5px 5px 5px" v-if="form.challan ">
                {{ this.form.title }}
              </div>
              <div class="col-6 text-end" style="padding:5px 5px 5px 5px" v-if="form.challan ">
                <small style="color: red; cursor: pointer"
                      ><b
                        ><span
                          @click="
                            openWindow(
                              `${
                                this.$store.state.imgUrl +
                                '/clientservice/' +
                                this.form.client_service_id +
                                '/' +
                                this.form.challan
                              }`
                            )
                          "
                          >Download</span
                        >
                        <!-- |
                        <span @click="deleteChallans(challan)">Delete</span> -->
                      </b></small
                    >
              </div>
              <div class="col-12" style="padding: 5px 5px 5px 5px">
                <label> Status</label>
                <select
                  style="padding: 5px 5px 5px 5px; height: 30px"
                  v-model="form.status"
                  class="form-select"
                  aria-label="Default select example"
                >
                  <option value="approve">Approve</option>
                  <option value="reject" selected>Reject</option>
                </select>
              </div>
              <div class="col-12" style="padding: 5px 5px 5px 5px">
                <label> Remark</label>
                <textarea
                  v-model="form.remark"
                  class="form-control"
                  name=""
                  id=""
                  rows="3"
                ></textarea>
                <small style="color: red" v-if="form.errors"
                            ><span v-if="form.errors.error"
                              ><span v-if="form.errors.error.remark">{{
                                form.errors.error.remark[0]
                              }}</span></span
                            ></small
                          >
              </div>
            </div>

            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-sm text-white"
                style="background-color: #f21300"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="submit"
                class="btn btn-sm text-white"
                style="background-color: #00364f"
              >
                Confirm
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>




  
      <!-- Right Sidebar starts -->
      <div class="modal modal-slide-in sidebar-todo-modal fade" id="new-task-modal">
    <div class="modal-dialog sidebar-sm">
      <div class="modal-content p-0">
        <form
          id="form-modal-todo"
          class="todo-modal needs-validation"
          novalidate
          onsubmit="return false"
        >
          <div
            class="modal-header align-items-center mb-1"
            style="background-color: #00364f"
          >
            <h5 class="modal-title text-white"><strong>Project Filter</strong></h5>
            <div
              class="todo-item-action d-flex align-items-center justify-content-between ms-auto text-white"
            >
              <span class="todo-item-favorite cursor-pointer me-75 text-white"
                ><i data-feather="star" class="font-medium-2"></i
              ></span>
              <i
                data-feather="x"
                class="cursor-pointer"
                data-bs-dismiss="modal"
                stroke-width="3"
                >X</i
              >
            </div>
          </div>
          <div class="modal-body flex-grow-1 pb-sm-0 pb-3">



            <ul class="list-group list-group-flush">
  <li class="list-group-item">
    <div class="form-check">
  <input @click="valueNullFilter()" v-model="filterType" value="challanNo" class="form-check-input" type="radio" name="flexRadioDefault" id="challanNo">
  <label class="form-check-label" for="challanNo">
  Challan NO.
  </label>
</div>
</li>


<li class="list-group-item"><div class="form-check">
  <input @click="valueNullFilter()" v-model="filterType" value="date" class="form-check-input"  type="radio" name="flexRadioDefault" id="date">
  <label class="form-check-label" for="date">
    Date
  </label>
</div></li>

<li class="list-group-item"><div class="form-check">
  <input @click="valueNullFilter()" v-model="filterType" value="client_service_id" class="form-check-input"  type="radio" name="flexRadioDefault" id="client_service_id">
  <label class="form-check-label" for="client_service_id">
    Project ID
  </label>
</div></li>
<li class="list-group-item">
    <div class="form-check">
  <input @click="valueNullFilter()" v-model="filterType" value="all" class="form-check-input" type="radio" name="flexRadioDefault" id="all">
  <label class="form-check-label" for="all">
    All
  </label>
</div>
  </li>
</ul>


<div class="pt-1 pb-1">
  <!-- <label for="exampleFormControlInput1" class="form-label">Email address</label> -->
 <input type="date" v-if="filterType == 'date' || filterType == 'dueDate' || filterType == 'completionDate'" class="form-control datepicker" v-model="filterValue" id="exampleFormControlInput1" placeholder="Enter Value">
 <input type="text" v-if="filterType == 'challanNo'" class="form-control" v-model="filterValue" id="exampleFormControlInput1" placeholder="Enter Value">
 <input type="text" v-if="filterType == 'client_service_id'" @change="findRecordeByProjectId" class="form-control" v-model="filterValue" id="exampleFormControlInput1" placeholder="Enter Value">
 







</div>

<button v-if="filterType != 'client_service_id'"  @click="loadchallans()" type="button" class="btn btn-success btn-sm">Success</button>



           
          </div>
        </form>
      </div>
    </div>
  </div>
  <!-- Right Sidebar ends -->
</template>

<script>
import Permissions from "../../../mixins/permission";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import moment from "moment";
import Spinner from "../../../components/professional/comman/Spinner.vue";
import Banner from "../../../components/professional/comman/Banner.vue";
// var _ = require("lodash");
import Form from "vform";
import jQuery from "jquery";
let $ = jQuery;
export default {
  name: "Challans",
  mixins: [Permissions],
  components: {
    Banner,
    Spinner,
  },
  data() {
    return {
      filterValue:'',
      filterType:'',
      pagination: {
        current_page: 0,
        from: 0,
        to: 0,
        total: 0,
        last_page: 0,
        per_page: 0,
        first_page_url: null,
        last_page_url: null,
        next_page_url: null,
        prev_page_url: null,
        path: null,
      },
      per_page: 10,

      loading: false,
      clients: [],
      catchStatus: "",
      client_service_id: null,
      stsCheck: "",
      challan_id: null,
      form: new Form({
        status: "",
        remark: "",
        errors: {},
      }),
      // permissions

      permissionCheck: {
        view: "",
        viewAny: "",
        kycView: "",
        kycCreate: "",
        kycUpdate: "",
        kycApprove: "",
      },
    };
  },
  methods: {
    dateTime(value) {
      return moment(value).format("DD-MM-YYYY");
    },
    findRecordeByProjectId(e){         
      this.$axios
        .get(`professional/clientservice?clientServiceNo=${e.target.value}`, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
       
          this.filterValue = res.data.data.data[0].id
          this.loadchallans()
        
        })
    },

    changePageNo(e) {
      this.per_page = e.target.value;
      this.loadchallans();
    },
    challanAssign(challan) {
      this.form = {}
      this.challan_id = challan.id;

      this.form = challan

            


    },
    valueAssign(client) {
      this.form.errors = {};
      this.form.client_service_id = client.id;
      this.stsCheck = client.status;
    },
    statusChange(status) {
      this.catchStatus = status;
    },
    statusChangeSave() {
      this.loading = true;
      this.$axios
        .post(`professional/clientservicechallan/approve/${this.challan_id}`, this.form, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          // console.log(res.data);
          res.data;
          // this.loadchallans();
          // this.loadchallanserviceservices();

          toast.success("Status Changed successfully.", {
            autoClose: 1000,
          });
          $("#chalanApprove").modal("hide");
          this.form = {};
          this.form.errors = {};
        })
        .catch((error) => {
          this.form.errors = error.response.data;
          if(this.form.errors.error.remark){
            this.form.errors = error.response.data;
          }else{
            this.$swal.fire({
            confirmButtonColor: "#00364f",
            icon: "error",
            text: error.response.data.error,
          });
          }
         
        });
      this.loadchallans().finally(() => (this.loading = false));
    },

    loadchallanservices() {
      this.loading = true;
      this.$axios
        .get("professional/clientservice?all=all", {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          // console.log(res.data.data.data);
          this.clients = res.data.data;
        })
        .finally(() => (this.loading = false));
    },
    loadchallans(page = null, pg = null) {
      console.log(page);
      console.log(pg);
      this.loading = true;
      var pageUrl = "";
      if (this.pagination.last_page_url && pg == "last") {
        pageUrl = pageUrl + this.pagination.last_page_url;
      } else if (this.pagination.first_page_url && pg == "first") {
        pageUrl = pageUrl + this.pagination.first_page_url;
      } else if (this.pagination.prev_page_url && pg == "prev") {
        pageUrl = pageUrl + this.pagination.prev_page_url;
      } else if (this.pagination.next_page_url && pg == "next") {
        pageUrl = pageUrl + this.pagination.next_page_url;
      }

      if (pg == null) {


     

                    if( this.filterType == 'all'){
                        this.filterValue = ''
                        this.filterType = ''
                      }


                      if(this.filterValue != null && this.filterType != 'all'){
                        pageUrl += `professional/clientservicechallan?per_page=${this.per_page}&${this.filterType}=${this.filterValue}`;
                      }else{
                        pageUrl += `professional/clientservicechallan?per_page=${this.per_page}`;
                      }




        
      } else {
        pageUrl += `&per_page=${this.pagination.per_page}`;
      }
      if (this.table_search) {
        pageUrl += `&searchTerm=${this.table_search}`;
      }
      if (page != null) {
        pageUrl += `&page=${page}`;
      }

      this.$axios
        .get(pageUrl, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res);
          this.challans = res.data.data.data;
          this.pagination = res.data.data;

          this.filterValue = ''
        })
        .finally(() => (this.loading = false));
    },
    openWindow(url) {
      window.open(`${url}`);
    },
    valueNullFilter() {
      this.filterValue = "";
    },
    loadPageTitle(){
        document.title =this.$store.state.professionalPageTitles.challans
      }
  },
  created() {
    if (
      this.$route.meta.redirectIfLoggedIn == "true" &&
      localStorage.getItem("accessToken") == null
    ) {
      this.$router.push({ name: "Login" });
    } else {
      this.loadchallans();
   
      this.loadPageTitle()
    }
  },
};
</script>
<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");

.custom-card {
  border-radius: 10px;
  box-shadow: 0px 0px 5px 0px rgba(206, 205, 205, 0.555);
  background-color: white;
}

@media screen and (max-width: 767px) {
  .mt-top-numbers {
    margin-top: -26px;
  }
}

.h1-heading {
  color: red;
  font-size: 3vw;
}

.font-heading {
  font-family: "Poppins", sans-serif;
  font-size: 3rem;
  color: rgb(216, 209, 209);
}

.text {
  font-family: "Poppins", sans-serif;
  color: #00364f;
}

.btns {
  display: inline-flex;
}

.tr-head {
  font-size: 11pt;
  margin-left: 25px;
  border-radius: 26px;
  background-color: #cdcdcd;
}

.table-scroll {
  overflow-y: auto;
}
.page-item.active .page-link {
  z-index: 3;
  border-radius: 5rem;
  background-color: #00364f;
  color: #fff !important;
  font-weight: 600;
}
h4[data-v-69e5a8da] {
  color: #fff;
  font-family: poppins, Sans-serif;
  font-weight: 600;
  font-size: 25;
}


.form-check-input:checked {
    background-color: #ffffff;
    border-color: #f21000;
    border-width: 5px;
}
.form-check-input:not(:disabled):checked {
    box-shadow: 0 2px 4px 0 rgb(225 0 0 / 28%);
}


.cus-p-1 {
  padding: 5px 5px 5px 5px;
}

@media only screen and (min-width: 600px) {
  .justify-content-center {
    justify-content: end !important;
  }
}

.card-footer {
  padding: 10px 10px 0px 10px;
}
</style>
